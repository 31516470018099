import { Icons } from '@/assets';

type Props = {
  isLoading: boolean;
};

export const LoadingCover = ({ isLoading, children }: React.PropsWithChildren<Props>) => {
  return (
    <>
      <div
        className={`z-50 w-screen h-screen flex justify-center items-center bg-white fixed inset-0 
      ${isLoading ? 'pointer-events-auto' : 'pointer-events-none'}
      ${isLoading ? 'opacity-100' : 'animate-fade-out'}`}>
        <div className="overflow-hidden">
          <Icons.LogoText />
        </div>
      </div>
      {!isLoading && children}
    </>
  );
};
