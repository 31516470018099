import { usePostHog } from 'posthog-js/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ResultChunk } from 'src/types/api';
import Layout from '../../components/Layout/Layout';
import LoadingGradient from '../../components/LoadingGradient/LoadingGradient';
import { PDFPreview } from '../../components/PDFPreview/PDFPreview';
import PDFReader from '../../components/PDFReader/PDFReader';
import { MAX_CHUNKS_PER_DOCUMENT } from '../../constants';
import { createNewSearch } from '../../state/actions/createNewSearch';
import { loadOldSearch, setParams } from '../../state/actions/searchParams';
import { sendSearchParams } from '../../state/actions/sendSearchParams';
import { useSearchStore } from '../../state/searchStore';
import devlog from '@/utils/devlog';
import { catchAppError } from '@/utils/createAppError';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { getOldSearch } from '@/services/firebase/functions/getOldSearch';
import { getDocumentFileUrl } from '@/services/api/utils';
import { sendErrorLog } from '@/services/firebase/functions/sendErrorLog';
import { useDocumentProperties } from '@/services/api/actions/getDocumentProperties';
import ErrorMessage from '@/common/ErrorMessage/ErrorMessage';

type ChunkPreviewProps = {
  onClick: (value: { number: number; id: number; offset: number }) => void;
  chunk: ResultChunk;
};
const ChunkPreview = ({ chunk, onClick }: ChunkPreviewProps) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const posthog = usePostHog();
  const { searchId = '' } = useParams();

  return (
    <div
      className="flex flex-col items-centerjustify-between cursor-pointer flex-shrink-0 px-1 gap-2"
      onClick={() => {
        posthog.capture(POSTHOG_EVENT.DOCUMENT_SNIPPET_CLICK, {
          chunkId: chunk.chunkId,
          documentId: chunk.documentId,
          searchId,
        });
        onClick({
          number: chunk.pageNumber,
          id: Math.random(),
          offset: chunk.heightOffset,
        });
      }}
      key={chunk.pageNumber + 1}>
      <PDFPreview
        noTextLayer
        showPageNumber
        hasLoaded={hasLoaded}
        onLoad={() => setHasLoaded(true)}
        chunk={chunk}
        className="rounded shadow-qura p-1"
        loadingLogoHeight={60}
        width={150}
      />
    </div>
  );
};

type ChunkPreviewColumnProps = {
  onClick: (value: { number: number; id: number; offset: number }) => void;
  chunks?: ResultChunk[];
  searchIsLoading?: boolean;
};
const ChunkPreviewColumn = ({ onClick, chunks, searchIsLoading }: ChunkPreviewColumnProps) => {
  const { t } = useTranslation();

  const sortedChunks = useMemo(() => {
    if (!chunks) return null;
    return [...chunks].sort((a, b) => a.pageNumber - b.pageNumber);
  }, [chunks]);

  if (!sortedChunks) return;

  return (
    <div className="flex flex-col items-stretch h-full">
      <div className="flex flex-col w-filter flex-shrink overflow-hidden bg-white shadow-qura rounded-md">
        <div className="border-b border-gray-200 text-sm text-center py-5 ">
          <p>{t('searchDocumentPage.title')}</p>
        </div>
        <div className="px-12 pt-10 pb-12 flex flex-col gap-8 overflow-y-auto items-stretch">
          {!searchIsLoading
            ? sortedChunks.map((chunk) => (
                <ChunkPreview chunk={chunk} onClick={onClick} key={chunk.chunkId} />
              ))
            : Array(MAX_CHUNKS_PER_DOCUMENT)
                .fill(undefined)
                .map((_, index) => <LoadingGradient className="w-36 h-36" key={index} />)}
        </div>
      </div>
    </div>
  );
};

const SearchDocumentPage = () => {
  const { searchId, documentId = '' } = useParams();
  const posthog = usePostHog();

  const [searchParams] = useSearchParams();
  const documentFromSearch = useSearchStore((state) =>
    searchId
      ? (state.searches[searchId]?.results?.documents.find(
          (doc) => doc.documentId === documentId,
        ) ?? null)
      : null,
  );

  useEffect(() => {
    if (!documentFromSearch && searchId) {
      getOldSearch(searchId)
        .then(({ result, submittedParams }) => {
          loadOldSearch(searchId, result, submittedParams);
          setParams(submittedParams);
        })
        .catch((err) => {
          sendErrorLog(err);
          console.error(err);
        });
    }
  }, [documentFromSearch, searchId]);

  const { data: documentProperties, error: documentPropertiesError } =
    useDocumentProperties(documentId);

  devlog(documentPropertiesError);

  const paramsPageNumber = searchParams.get('page');
  const paramPageOffset = searchParams.get('offset');

  const [pageNumber, setPageNumber] = useState({
    number: parseInt(paramsPageNumber || '0'),
    id: Math.random(),
    offset: parseInt(paramPageOffset || '0'),
  });

  const { t } = useTranslation();

  const firstChunkPageNumber = 0;

  useEffect(() => {
    if (!paramsPageNumber) {
      setPageNumber({
        number: firstChunkPageNumber || 0,
        id: Math.random(),
        offset: 0,
      });
    }
  }, [firstChunkPageNumber, paramsPageNumber]);

  const navigate = useNavigate();

  const query = useSearchStore((state) => state.currentParams?.query);

  const onSearch = () => {
    createNewSearch()
      .then(({ searchId }) => {
        sendSearchParams(searchId);
        navigate(`/search/${searchId}`);
      })
      .catch(() => {});
  };

  const selectDocument = (documentId: string) => {
    posthog.capture(POSTHOG_EVENT.SEARCH_DOCUMENT_SUFGGESTION_SELECT, { documentId, searchId });
    navigate(`/document/${documentId}`);
  };

  const backToSearchClick = () => {
    if (searchId) {
      posthog.capture(POSTHOG_EVENT.NAVIGATE_BACK_TO_SEARCH_RESULT, {
        from: 'document',
        searchId,
        documentId,
      });
      navigate(`/search/${searchId}`);
    } else {
      posthog.capture(POSTHOG_EVENT.NAVIGATE_BACK_HOME, { from: 'document', documentId, searchId });
      navigate('/');
    }
  };

  return (
    <Layout
      query={query}
      onSearch={onSearch}
      showBackButtonLink={`/search/${searchId}`}
      originalSearchId={searchId}
      selectDocument={selectDocument}
      contentClassName="w-[calc(100%-700px)] max-w-[850px] min-w-[75%] lg:min-w-[600px]"
      leftContent={() =>
        documentFromSearch && (
          <ChunkPreviewColumn onClick={setPageNumber} chunks={documentFromSearch.outputChunks} />
        )
      }>
      {!documentPropertiesError ? (
        <PDFReader
          withoutHighlightingUrl={getDocumentFileUrl(documentId)}
          withHighlightingUrl={searchId ? getDocumentFileUrl(documentId, searchId) : undefined}
          documentProperties={documentProperties}
          pageNumber={pageNumber}
          legalId={documentProperties?.legalId ?? ''}
        />
      ) : (
        <div className="flex flex-col gap-2 items-start">
          <ErrorMessage error={catchAppError(documentPropertiesError)} className="text-xs" />
          <button
            className="bg-white border border-qura-neutral-silver text-qura-neutral-jet hover:bg-qura-neutral-ghost text-xs px-2 py-1.5 rounded"
            onClick={backToSearchClick}>
            {t('searchDocumentPage.backToSearch')}
          </button>
        </div>
      )}
    </Layout>
  );
};

export default SearchDocumentPage;
