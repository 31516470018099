import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AuthBackground } from '../../components/AuthBackground';
import { AuthContainerCard } from '../../components/AuthContainerCard';
import { AuthDescription } from '../../components/AuthDescription';
import { AuthFooter } from '../../components/AuthFooter';
import { AuthInputCard } from '../../components/AuthInputCard';
import { AuthLogo } from '../../components/AuthLogo';
import { AuthSubmitButton } from '../../components/AuthSubmitButton';
import { sendConfirmationEmail } from '@/services/firebase/functions/sendConfirmationEmail';
import { useCurrentUser } from '@/services/firebase/context/useCurrentUser';
import { sendErrorLog } from '@/services/firebase/functions/sendErrorLog';

export const EmailConfirmationPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const disabledTimeoutRef = useRef<number | null>(null);
  const [sendAgainDisabled, setSendAgainDisabled] = useState(false);

  const isConfirmed = useMemo(() => location.search.includes('confirmed'), [location.search]);

  const [searchParams] = useSearchParams();
  const emailInParams = searchParams.get('email');

  const { user } = useCurrentUser();
  const email = user?.email || emailInParams;
  const emailVerified = user?.emailVerified;

  // if the user is logged in and accesses this page
  // and they have already confirmed their email
  // we redirect them to the search page
  useEffect(() => {
    if (emailVerified) {
      navigate('/');
    }
  }, [emailVerified, navigate]);

  useEffect(() => {
    return () => {
      if (disabledTimeoutRef.current) {
        window.clearTimeout(disabledTimeoutRef.current);
      }
    };
  }, []);

  const resendConfirmationClick = () => {
    if (email === null) return;
    setIsLoading(true);
    setSendAgainDisabled(true);
    disabledTimeoutRef.current = window.setTimeout(() => {
      setSendAgainDisabled(false);
    }, 5000);
    sendConfirmationEmail(email)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        sendErrorLog(err);
        setIsLoading(false);
      });
  };

  const loginClick = () => {
    navigate('/auth');
  };

  return (
    <AuthBackground>
      <AuthContainerCard>
        <AuthInputCard>
          <div className="h-12" />
          <AuthLogo />
          <AuthDescription
            text={
              isConfirmed
                ? t('confirmEmailPage.confirmedMessage')
                : t('confirmEmailPage.confirmEmailMessage')
            }
          />
          {!isConfirmed && (
            <>
              <p className="self-center bg-black rounded-smaller px-4 py-1 text-white mx-3 mb-4 text-center tracking-wide">
                {email}
              </p>
              <button
                onClick={resendConfirmationClick}
                disabled={sendAgainDisabled}
                className={`${isLoading ? 'animate-pulse cursor-default' : sendAgainDisabled ? '' : 'hover:bg-qura-neutral-silver'} border ${sendAgainDisabled ? 'border-qura-neutral-silver/50' : 'border-qura-neutral-silver'} disabled:opacity-50 text-xs font-medium bg-qura-neutral-ghost px-2 py-1 self-center rounded-smaller`}>
                {isLoading ? t('confirmEmailPage.isLoading') : t('confirmEmailPage.resendLink')}
              </button>
            </>
          )}
          <div className="h-20" />
          <AuthSubmitButton onClick={loginClick}>
            {t('confirmEmailPage.backToLogin')}
          </AuthSubmitButton>
          <div className="h-12" />
        </AuthInputCard>
      </AuthContainerCard>
      <AuthFooter />
    </AuthBackground>
  );
};
