/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import posthog from 'posthog-js';
import { useNavigate } from 'react-router-dom';
import SearchBarDropdownOption from '@/features/Search/components/SearchBar/SearchBarDropdownOption.tsx';
import { Icons } from '@/assets';
import {
  SearchHistoryOption,
  useFullHistoryInfiniteScroll,
} from '@/services/firebase/functions/getFullHistorySearched.ts';
import { POSTHOG_EVENT } from '@/services/posthog/events.ts';

const BOTTOM_SCROLL_THRESHOLD = 1000;

type Props = {
  onClose: () => void;
  onSearch: (query: string) => void;
};
const SearchHistoryModal: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    data: historyData,
    error,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
  } = useFullHistoryInfiniteScroll();
  const navigate = useNavigate();

  const isFetchingNextPageRef = useRef(false);

  const historySections = useMemo(() => {
    const falttened = historyData?.pages.map((item) => item.searches || []).flat() ?? [];
    const sections: { at: Date; searches: SearchHistoryOption[] }[] = [];

    let lastDate = null as Date | null;

    falttened.forEach((search) => {
      const date = new Date(search.submittedAt * 1000);
      if (
        lastDate === null ||
        date.toISOString().split('T')[0] !== lastDate.toISOString().split('T')[0]
      ) {
        sections.push({ at: date, searches: [] });
      }
      sections[sections.length - 1].searches.push(search);
      lastDate = date;
    });
    return sections;
  }, [historyData]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.shiftKey) {
      return;
    }

    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        onClose();
        break;
    }
  };

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => document.body.classList.remove('overflow-hidden');
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (
        isFetchingNextPageRef.current === false &&
        container &&
        Math.ceil(container.scrollTop) + container.clientHeight >=
          container.scrollHeight - BOTTOM_SCROLL_THRESHOLD
      ) {
        console.log('fetching next page');
        isFetchingNextPageRef.current = true;
        fetchNextPage().finally(() => {
          isFetchingNextPageRef.current = false;
        });
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  const selectHistoryOption = (option: SearchHistoryOption) => {
    posthog.capture(POSTHOG_EVENT.SEARCH_HISTORY_SELECT, {
      searchParams: option.content,
      searchId: option.searchId,
      query: option.content.query,
    });
    navigate(`/search/${option.searchId}`);
    onClose();
  };

  return (
    <>
      <div className="fixed z-20 inset-0 flex items-center justify-center">
        <div className="fixed inset-0 bg-black/50" onClick={onClose} />
        <div className="flex flex-col w-[800px] h-[500px] max-h-[90%] max-w-[90%] bg-white rounded-[28px] shadow-lg relative pb-7">
          <h2 className="text-base font-medium pt-7 pb-2 pl-9">
            {t('searchHistory.searchHistory')}
          </h2>
          <div
            ref={containerRef}
            className="flex flex-col relative flex-1 overflow-y-auto gap-10 scroll-m-5 overflow-auto">
            {isFetching && !isFetchingNextPage ? (
              <div className="flex flex-1 justify-center items-center">
                <p className="tracking-wide animate-pulse">{t('common.loading')}</p>
              </div>
            ) : error === null ? (
              <>
                <div className="h-4" />
                {historySections.map((section) => (
                  <div key={section.at.toISOString()} className={'flex flex-col gap-1'}>
                    <HistoryDate date={section.at.getTime()} />
                    <div className={'flex flex-col gap-3 px-9'}>
                      {section.searches.map((search) => (
                        <HistoryCard
                          key={search.searchId}
                          queryOption={search}
                          selectQueryOption={selectHistoryOption}
                        />
                      ))}
                    </div>
                  </div>
                ))}
                <div className="h-4" />
              </>
            ) : (
              <p className="text-qura-red text-m">{t('errors.unknown')}</p>
            )}
            {isFetchingNextPage && (
              <div className="flex flex-1 justify-center items-center">
                <p className="tracking-wide animate-pulse">{t('common.loading')}</p>
              </div>
            )}
          </div>

          <button
            className="absolute top-6 right-7 text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={onClose}>
            <Icons.Close />
          </button>
        </div>
      </div>
    </>
  );
};

type HistoryCardProps = {
  queryOption: SearchHistoryOption;
  selectQueryOption: (option: SearchHistoryOption) => void;
};
const HistoryCard: FC<HistoryCardProps> = ({ queryOption, selectQueryOption }) => {
  return (
    <div className="rounded-[8px] shadow-qura border-1 border-gray-100 transition-all relative overflow-visible py-0.5 px-1">
      <SearchBarDropdownOption
        selected={false}
        title={queryOption.content.query}
        titleIcon="Clock"
        onClick={() => selectQueryOption(queryOption)}
      />
    </div>
  );
};

type HistoryDateProps = {
  date: number;
};
const HistoryDate: FC<HistoryDateProps> = ({ date }) => {
  const { t } = useTranslation();

  function daysBetweenDates(date1: Date, date2: Date): number {
    const midnightDate1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const midnightDate2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    const diffTime = Math.abs(midnightDate2.getTime() - midnightDate1.getTime());
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24) + 0.5);

    return diffDays;
  }

  const months = (t('common.month', { returnObjects: true }) ?? []) as string[];
  const lastWeekdays = (t('common.lastWeekday', { returnObjects: true }) ?? []) as string[];
  const weekdays = (t('common.weekday', { returnObjects: true }) ?? []) as string[];

  const dateString = useMemo(() => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const weekday = dateObj.getDay();
    const month = dateObj.getMonth();
    const dayDiff = daysBetweenDates(new Date(), dateObj);

    if (dayDiff === 0) {
      return `${t('common.today')} - ${weekdays[weekday]} ${day} ${months[month]} ${year}`;
    } else if (dayDiff === 1) {
      return `${t('common.yesterday')} - ${weekdays[weekday]} ${day} ${months[month]} ${year}`;
    } else if (dayDiff < 7) {
      return `${lastWeekdays[weekday]} - ${day} ${months[month]} ${year}`;
    } else {
      return `${weekdays[weekday]} ${day} ${months[month]} ${year}`;
    }
  }, [date]);

  return (
    <div className="sticky top-0 bg-white z-20 pt-1 pb-3 px-9">
      <p className="font-normal text-xs text-qura-neutral-balanced">
        {dateString.charAt(0).toUpperCase() + dateString.slice(1)}
      </p>
    </div>
  );
};

export default SearchHistoryModal;
