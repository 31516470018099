import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { setQuery } from '../../state/actions/searchParams';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { Icons } from '@/assets';

type Props = {
  onSearch: () => void;
};

export const ExamplePrompts = (props: Props) => {
  const { onSearch } = props;
  const { t } = useTranslation();
  const posthog = usePostHog();

  const searchWithPrompt = (prompt: string) => {
    posthog.capture(POSTHOG_EVENT.SEARCH_EXAMPLE_SELECT, { prompt });
    setQuery(prompt);
    onSearch();
  };

  const examplePrompts = (t('examplePrompts', { returnObjects: true }) ?? []) as string[];

  return (
    <div className="flex flex-col items-start gap-3 mt-10 text-xs">
      <p className=" text-qura-neutral-light">{t('searchPromptPage.subtitle')}</p>
      {examplePrompts.map((prompt, index) => {
        return (
          <button
            key={index}
            onClick={() => searchWithPrompt(prompt)}
            className="flex bg-white px-2 py-3 shadow-md hover:bg-qura-neutral-ghost rounded-md gap-2">
            <Icons.ArrowTiltedUpRight className=" text-qura-neutral-light" />
            <p className="text-qura-neutral-jet text-left font-medium">{prompt}</p>
          </button>
        );
      })}
    </div>
  );
};
