import { AxiosError } from 'axios';
import { createAppError } from './createAppError';

export const mapAxiosError = (error: AxiosError) => {
  let message = '';

  if (error.config) {
    if (error.config.method) {
      message = error.config.method.toUpperCase() + ' ';
    }
    message += error.config.url;
  } else {
    message = 'Axios request';
  }

  message += ' failed';

  if (error.response) {
    message += ' with ' + error.response.status;
    if (error.response.data) {
      message += ` "${JSON.stringify(error.response.data)}"`;
    }
  }

  return createAppError('unknown', message);
};
