import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { catchAndLogError } from '../../firebase/functions/sendErrorLog';
import { availableFiltersSchema } from '../apiValidation';
import { RESOURCE_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { mapAxiosError } from '@/utils/mapAxiosError';

export async function getFilterTagsRequest() {
  const token = await getAuthToken();
  const response = await axios
    .get(`${RESOURCE_API_URL}/database/filters/tags`, standardAxiosOptions(token))
    .catch((error: AxiosError) => {
      throw mapAxiosError(error);
    });
  return availableFiltersSchema.parse(response.data);
}

export const useFilterTags = () => {
  return useQuery({
    queryKey: ['filter_tags'],
    queryFn: () => catchAndLogError(() => getFilterTagsRequest()),
  });
};
