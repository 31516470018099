import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthBackground } from '../../components/AuthBackground';
import { AuthContainerCard } from '../../components/AuthContainerCard';
import { AuthDescription } from '../../components/AuthDescription';
import { AuthError } from '../../components/AuthError';
import { AuthFooter } from '../../components/AuthFooter';
import { AuthInputCard } from '../../components/AuthInputCard';
import { AuthLogo } from '../../components/AuthLogo';
import { AuthSubmitButton } from '../../components/AuthSubmitButton';
import AuthTextInput from '../../components/AuthTextInput';
import { createAppError } from '@/utils/createAppError';
import { AppError } from '@/common/ErrorMessage/ErrorMessage';
import { Icons } from '@/assets';
import { resetPassword } from '@/services/firebase/functions/resetPassword';
import { sendErrorLog } from '@/services/firebase/functions/sendErrorLog';

export const PasswordResetPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<AppError | null>(null);

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [params] = useSearchParams();
  const oobCode = params.get('oobCode');

  const resetPasswordClick = async () => {
    if (!password || !confirmPassword) {
      setError(createAppError('message', 'Please fill in all fields.'));
      return;
    }

    if (password !== confirmPassword) {
      setError(createAppError('message', 'Passwords do not match.'));
      return;
    }

    if (!oobCode) {
      setError(createAppError('message', 'Invalid reset password code.'));
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      await resetPassword(oobCode, password, confirmPassword);
      setSuccess(true);
    } catch (error) {
      sendErrorLog(error);
      setError(createAppError('unknown', 'Failed to reset password.'));
    }

    setIsLoading(false);
  };

  const loginClick = () => {
    navigate('/auth');
  };

  return (
    <AuthBackground>
      <AuthContainerCard>
        <AuthInputCard>
          <div className="h-12" />
          <AuthLogo />
          <AuthDescription text={t('resetPasswordPage.resetPassword')} />
          {success ? (
            <>
              <p className="text-black text-sm tracking-wide mb-5 mt-2 text-center">
                {t('resetPasswordPage.success.passwordReset')}
              </p>
              <AuthSubmitButton onClick={loginClick}>
                {t('resetPasswordPage.success.backToLogin')}
              </AuthSubmitButton>
            </>
          ) : (
            <>
              <AuthTextInput
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                id="password"
                value={password}
                placeholder={t('resetPasswordPage.passwordPlaceholder')}
                Icon={Icons.Lock}
                label={t('common.password')}
                isError={!!error}
              />
              <div className="h-5" />
              <AuthTextInput
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                id="confirm-password"
                value={confirmPassword}
                placeholder={t('resetPasswordPage.passwordPlaceholder')}
                Icon={Icons.Lock}
                label={t('common.confirmPassword')}
                isError={!!error}
              />
              <div className="h-2" />
              <AuthError error={error} />
              <button
                onClick={loginClick}
                className="self-start text-medium hover:underline text-inter text-xs li">
                <Icons.Arrow className="transform rotate-90 h-[5px] mb-[2px] inline-block" />
                {t('resetPasswordPage.login')}
              </button>
              <div className="h-16" />
              <AuthSubmitButton showLoading={isLoading} onClick={resetPasswordClick}>
                {t('resetPasswordPage.resetPassword')}
              </AuthSubmitButton>
            </>
          )}
          <div className="h-12" />
        </AuthInputCard>
      </AuthContainerCard>
      <AuthFooter />
      <ToastContainer />
    </AuthBackground>
  );
};
