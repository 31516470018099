import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { MAX_CHUNKS_PER_DOCUMENT, MAX_DOCUMENTS_PER_SEARCH } from '../constants';
import { SearchParams, SearchResult } from '@/types/api';
import { AppError } from '@/common/ErrorMessage/ErrorMessage';

interface SearchState {
  currentParams: SearchParams;
  searches: Record<
    string,
    {
      ws: WebSocket | null;
      results: SearchResult | null;
      submittedParams: SearchParams;
    }
  >;
  isCreatingSearch: boolean;
  searchIdHistory: string[];
  error: AppError | null;
}

const initialState: SearchState = {
  currentParams: {
    query: '',
    maxChunksPerDocument: MAX_CHUNKS_PER_DOCUMENT,
    maxDocuments: MAX_DOCUMENTS_PER_SEARCH,
    searchMode: {
      noChunks: false,
      noAiFilter: false,
    },
    filter: {
      tagIds: [],
      documentIds: [],
      afterDate: null,
      beforeDate: null,
    },
  },
  isCreatingSearch: false,
  searchIdHistory: [],
  error: null,
  searches: {},
};

export const useSearchStore = create<SearchState>()(
  devtools(
    immer(
      subscribeWithSelector(() => ({
        ...initialState,
      })),
    ),
  ),
);
