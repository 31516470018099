export const POSTHOG_EVENT = {
  APP_ERROR: 'app_error',
  FEEDBACK_SENT: 'feedback_sent',
  PAGE_CHANGE: 'page_change',

  // home page
  SEARCH_START: 'search_start',
  SEARCH_COMPLETE: 'search_complete',
  SEARCH_EXAMPLE_SELECT: 'search_example_select',
  SEARCH_HISTORY_SELECT: 'search_history_select',
  SEARCH_DOCUMENT_SUFGGESTION_SELECT: 'search_document_suggestion_select',
  SEARCH_COMPLETION_SUGGESTION_SELECT: 'search_completion_suggestion_select',

  // search result page
  FILTER_TAG_SELECT: 'filter_tag_select',
  FILTER_TAG_DESELECT: 'filter_tag_deselect',
  FILTER_RESET: 'filter_reset',
  FILTER_APPLY: 'filter_apply',
  FILTER_START_DATE_SELECT: 'filter_start_date_select',
  FILTER_END_DATE_SELECT: 'filter_end_date_select',
  SEARCH_RESULT_EXPAND: 'search_result_expand',
  SEARCH_RESULT_OPEN: 'search_result_open',
  SEARCH_TRY_AGAIN: 'search_try_again',
  NAVIGATE_BACK_HOME: 'navigate_back_home',

  // document result page
  DOWNLOAD_PDF_WITH_HIGHLIGHTING: 'download_pdf_with_highlighting',
  DOWNLOAD_PDF_WITHOUT_HIGHLIGHTING: 'download_pdf_without_highlighting',
  DOCUMENT_SNIPPET_CLICK: 'document_snippet_click',
  NAVIGATE_BACK_TO_SEARCH_RESULT: 'navigate_back_to_search_result',
};
